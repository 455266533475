import React, { useState, useEffect } from 'react';
import MessageList from './MessageList';
import MessageInput from './MessageInput';
import UserInfoModal from './UserInfoModal';
import FeedbackForm from './FeedbackForm';
import { toast } from 'react-toastify';
import api from '../axios';
import { sendGtagEvent } from '../analytics';

const ChatWindow = ({ onEndChat }) => {
  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);

  useEffect(() => {
    if (userInfo && userInfo.firstName) {
      const initialMessage = {
        sender: 'bot',
        text: `Willkommen, ${userInfo.firstName}! Wie kann ich Ihnen helfen?`,
        timestamp: new Date(),
      };
      setMessages([initialMessage]);
    }
  }, [userInfo]);

  const handleUserInfoSubmit = (info) => {
    setUserInfo(info);
  };

  const handleSendMessage = async (messageText) => {
    if (!userInfo || !userInfo.chatId) {
      console.error('chatId ist nicht verfügbar.');
      toast.error('Es gab ein Problem mit der Chat-Verbindung. Bitte starten Sie den Chat neu.');
      return;
    }

    const userMessage = {
      sender: 'user',
      text: messageText,
      timestamp: new Date(),
    };
    setMessages((prevMessages) => [...prevMessages, userMessage]);

    setIsTyping(true);

    try {
      const response = await api.post(`/api/Chat/${userInfo.chatId}/SendMessage`, {
        userMessage: messageText,
      });
    
      const aiMessageText = response.data.content;
    
      const aiMessage = {
        sender: 'bot',
        text: aiMessageText,
        timestamp: new Date(),
      };
    
      setMessages((prevMessages) => [...prevMessages, aiMessage]);
    } catch (error) {
      if (error.response && error.response.status === 429) {
        // Statuscode 429 abfangen
        toast.error('Zu viele Anfragen. Bitte warten Sie einen Moment, bevor Sie eine weitere Nachricht senden.');
        sendGtagEvent('rate_limit_reached', {
          event_category: 'Error',
          event_label: 'User hit rate limit'
        });        
      } else {
        console.error('Fehler beim Senden der Nachricht:', error);
        toast.error('Es gab ein Problem beim Senden Ihrer Nachricht. Bitte versuchen Sie es erneut.');
      }
    } finally {
      setIsTyping(false);
    }
    
  };

  const handleEndChat = async () => {
    if (userInfo && userInfo.chatId) {
      try {
        if(showFeedbackForm)
        {
          onEndChat();
          return;
        }

        await api.post(`/api/Chat/${userInfo.chatId}/End`);
        setShowFeedbackForm(true);
      } catch (error) {
        console.error('Fehler beim Beenden des Chats:', error);
        toast.error('Es gab ein Problem beim Beenden des Chats. Bitte versuchen Sie es erneut.');
      }
    } else {
      onEndChat();
    }
  };

  const handleFeedbackSubmitted = () => {
    onEndChat();
  };

  return (
    <div className="chat-window">
      <button className="end-chat-button" onClick={handleEndChat}>
        ✕
      </button>
      {!userInfo ? (
        <UserInfoModal onSubmit={handleUserInfoSubmit} />
      ) : showFeedbackForm ? (
        <FeedbackForm chatId={userInfo.chatId} onFeedbackSubmitted={handleFeedbackSubmitted} />
      ) : (
        <>
          <MessageList messages={messages} isTyping={isTyping} />
          <MessageInput onSendMessage={handleSendMessage} />
        </>
      )}
    </div>
  );
};

export default ChatWindow;
