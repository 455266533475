import React, { useState } from 'react';
import { TextField, IconButton } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { sendGtagEvent } from '../analytics';

const MessageInput = ({ onSendMessage }) => {
  const [messageText, setMessageText] = useState('');

  const handleSend = () => {
    if (messageText.trim()) {
      onSendMessage(messageText);
      setMessageText('');
      sendGtagEvent('message_sent', {
        event_category: 'Chat',
        event_label: 'User sent a message'
      });
      
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  return (
    <div className="message-input">
      <TextField
        variant="outlined"
        placeholder="Ihre Nachricht..."
        value={messageText}
        onChange={(e) => setMessageText(e.target.value)}
        onKeyPress={handleKeyPress}
        fullWidth
        multiline
        maxRows={4}
        InputProps={{
          endAdornment: (
            <IconButton color="primary" onClick={handleSend}>
              <SendIcon />
            </IconButton>
          ),
        }}
      />
    </div>
  );
};

export default MessageInput;
